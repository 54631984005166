import { appConfig } from '@/common/appConfig';
import { languageUtils } from '@/utils/languageUtils';
import { addNativeAppStateListener } from '@/utils/nativeAppUtils';
import { IonicVue, isPlatform } from '@ionic/vue';
import * as Sentry from '@sentry/capacitor';
import * as SentryVueSDK from '@sentry/vue';
import { browserTracingIntegration, replayIntegration } from '@sentry/vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import VueApp from './App.vue';
import router from './router';
import './theme/app.css';
import '@/utils/validatorUtils';
import { ENVIRONMENT_PRODUCTION } from '@/types/environment';
import type { Mode } from '@ionic/core';
import VuePiwikPro, { DownloadAndOutlink } from '@piwikpro/vue-piwik-pro';

const app = createApp(VueApp);

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

const ionicConfig = () => {
	let config = {
		rippleEffect: false,
		mode: 'ios' as Mode,
	};

	if (isPlatform('android')) {
		config = {
			...config,
			rippleEffect: true,
			mode: 'md' as Mode,
		};
	}

	return config;
};

app.use(IonicVue, ionicConfig());
app.use(pinia);
app.use(languageUtils);
app.use(router);
/*
TODO: re-add vue google login later, when we want to add this feature.
app.use(vue3GoogleLogin, {
  clientId: '1066158285958-5mvfgg8pn9vklpu8nfi7ogdvol6p9vlv.apps.googleusercontent.com',
  autoLogin: true,
  idConfiguration: {
    use_fedcm_for_prompt: true,
  },
  buttonConfig: {
    size: 'large',
    text: 'signin_with',
  },
});
*/

router.isReady().then(async () => {
	app.mount('#app');
	await addNativeAppStateListener();
});

Sentry.init(
	{
		app,
		dsn: appConfig.sentry.dsn,
		environment: appConfig.sentry.environment,
		integrations: [
			browserTracingIntegration({
				router: router,
			}),
			replayIntegration({
				maskAllInputs: appConfig.sentry.environment === ENVIRONMENT_PRODUCTION, // mask for production only
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		// Performance Monitoring
		tracesSampleRate: appConfig.sentry.sampleRate[appConfig.sentry.environment],
		replaysSessionSampleRate: appConfig.sentry.sampleRate[appConfig.sentry.environment],
		replaysOnErrorSampleRate: 1.0,
	},
	// Forward the init method from @sentry/vue
	SentryVueSDK.init
);

VuePiwikPro.initialize(appConfig.piwik.containerId, appConfig.piwik.containerUrl, {
	nonce: 'nonce-string',
});

DownloadAndOutlink.enableLinkTracking();
