export enum ContractStatus {
	CONTRACT_EXISTING = 'CONTRACT_EXISTING',
	CONTRACT_ONBOARDED = 'CONTRACT_ONBOARDED',
	CONTRACT_OFFER_REQUESTED = 'CONTRACT_OFFER_REQUESTED',
	CONTRACT_OFFER_ACCEPTED = 'CONTRACT_OFFER_ACCEPTED',
	CONTRACT_APPLICATION = 'CONTRACT_APPLICATION',
	CONTRACT_SIGNED = 'CONTRACT_SIGNED',
	CONTRACT_OPTED_OUT = 'CONTRACT_OPTED_OUT',
	CONTRACT_SUSPENDED = 'CONTRACT_SUSPENDED',
}

export const APP_CONTRACT_GET_YOUR_OFFER = 'GET_YOUR_OFFER' as const;
export const APP_CONTRACT_IN_CONSULTATION = 'IN_CONSULTATION' as const;
export const APP_CONTRACT_OFFER_REQUESTED = 'OFFER_REQUESTED' as const;
export const APP_CONTRACT_OFFER_RECEIVED = 'OFFER_RECEIVED' as const;
export const APP_CONTRACT_WAITING_FOR_CONTRACT = 'WAITING_FOR_CONTRACT' as const;
export const APP_CONTRACT_SIGNED = 'CONTRACT_SIGNED' as const;
export const APP_CONTRACT_EXISTING = 'CONTRACT_EXISTING' as const;
export const APP_CONTRACT_OPTED_OUT = 'CONTRACT_OPTED_OUT' as const;
export const APP_CONTRACT_SUSPENDED = 'CONTRACT_SUSPENDED' as const;

export const AllAppContractStatus = [
	APP_CONTRACT_GET_YOUR_OFFER,
	APP_CONTRACT_IN_CONSULTATION,
	APP_CONTRACT_OFFER_REQUESTED,
	APP_CONTRACT_OFFER_RECEIVED,
	APP_CONTRACT_WAITING_FOR_CONTRACT,
	APP_CONTRACT_SIGNED,
	APP_CONTRACT_EXISTING,
	APP_CONTRACT_OPTED_OUT,
	APP_CONTRACT_SUSPENDED,
] as const;

export type AppContractStatus = (typeof AllAppContractStatus)[number];
