import { appConfig } from '@/common/appConfig';
import type { GetLanguageCodeResult } from '@capacitor/device';

export enum SupportedLanguage {
	DE = 'de',
	EN = 'en',
	DEFORMAL = 'de-formal',
}

export function transformLanguage(language: string | GetLanguageCodeResult): SupportedLanguage {
	switch (language) {
		case 'de':
			return SupportedLanguage.DE;
		case 'en':
			return SupportedLanguage.EN;
		case 'de-formal':
			return SupportedLanguage.DEFORMAL;
		default:
			return appConfig.variables.defaultLanguage;
	}
}
