import {
	ENVIRONMENT_DEVELOPMENT,
	ENVIRONMENT_ENDPOINT,
	ENVIRONMENT_PRODUCTION,
	ENVIRONMENT_STAGING,
	type ENVIRONMENT_TYPE,
	ENVIRONMENT_URL,
} from '@/types/environment';
import { HeaderName } from '@/types/header-name.enum';
import { SupportedLanguage } from '@/types/language.enum';
import { getEnvironment } from '@/utils/environmentUtils';

const environment: ENVIRONMENT_TYPE = getEnvironment();

const apiEndpoint = ENVIRONMENT_ENDPOINT[environment].api;
const assetEndpoint = ENVIRONMENT_ENDPOINT[environment].assets;

export const appConfig = {
	variables: {
		environment: environment,
		apiBaseUrl: apiEndpoint,
		apiAuthenticationHeader: HeaderName.X_API_KEY,
		assetBaseUrl: assetEndpoint,
		defaultLanguage: SupportedLanguage.DE,
		privacyPolicyUrl: `https://${ENVIRONMENT_URL[environment]}/privacy`,
		termsAndConditionsUrl: `https://${ENVIRONMENT_URL[environment]}/terms`,
		faqHelpdeskUrlDe: 'https://deguragmbh.freshdesk.com/de/support/home',
		faqHelpdeskUrlEn: 'https://deguragmbh.freshdesk.com/en/support/home',
		imprintUrl: `https://${ENVIRONMENT_URL[environment]}/imprint`,
		sseUrl: `${apiEndpoint}/sse`,
	},
	sentry: {
		environment: environment,
		dsn: 'https://76da06b4ad1e7648171d5f827fe929b3@o1163979.ingest.sentry.io/4506218780950528',
		sampleRate: {
			[ENVIRONMENT_DEVELOPMENT]: 0,
			[ENVIRONMENT_STAGING]: 0.33,
			[ENVIRONMENT_PRODUCTION]: 0.1,
		},
	},
	piwik: {
		containerId: ENVIRONMENT_ENDPOINT[environment].piwikContainerId,
		containerUrl: 'https://degura.containers.piwik.pro',
	},
};
