<script setup lang="ts">
import { ONBOARDING_STEP_COUNT } from '@/common/constants';
import router from '@/router';
import { RouteNames } from '@/router/routeNames';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const onboardingSteps = ref();

onMounted(() => {
	onboardingSteps.value = Array.from(
		{ length: ONBOARDING_STEP_COUNT },
		(_, i) => RouteNames[`OnboardingStep${i + 1}` as keyof typeof RouteNames]
	);
});
</script>

<template>
  <ion-footer id="onboardingFooter">
    <ul class="step-indicator" data-cy="onboarding_footer_step_indicator_wrapper">
      <li
        v-for="(step, index) in onboardingSteps"
        :key="step"
        :data-cy="`onboarding_footer_step_indicator_item_${index}`"
        :class="{ active: index + 1 === route.meta.step }"
        @click="router.push({ name: step })"
      ></li>
    </ul>
  </ion-footer>
</template>
<style>
ion-footer {
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  background: rgba(var(--ion-color-light-rgb), 0.66);

}

.step-indicator {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: clamp(32px, 2.25rem, 42px);
  margin: 0;
  padding: 16px 0 24px;
  min-height: 44px;
  list-style-type: none;

  & > li {
    display: flex;
    position: relative;
    flex-basis: clamp(10px, 0.75rem, 16px);
    transition: 0.2s ease;
    border-radius: 200%;
    background-color: var(--ion-color-light-shade);
    aspect-ratio: 1;
    height: clamp(10px, 0.75rem, 16px);

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: max(100%, 2.75rem);
      height: max(100%, 2.75rem);
      content: '';
    }

    &.active {
      background-color: var(--ion-color-primary);
    }

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background-color: var(--ion-color-secondary);
      }
    }
  }
}

#sentry-feedback {
  --bottom: max(calc(var(--ion-safe-area-bottom) + 48px), 56px);
}
</style>
