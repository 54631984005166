const validPasswordRegexp = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*\d)+)(?=(.*[^a-zA-Z0-9])+)(?!.*\s).{8,}$/;
const codeRegex = /^[0-9]$/;

export const isEmail = (email: string) => {
	const regExp = /^(?!mailto:)[^:]+@\S+\.[A-Za-z]{2,}$/;
	return regExp.test(email);
};
export const isValidPassword = (password: string) => {
	return validPasswordRegexp.test(password);
};

export const isCodeNum = (num: string) => {
	return codeRegex.test(num);
};

export const isValidFilename = (filename: string) => {
	return /^(?! )[a-zA-Z0-9\-_.() ]+(?<![ .])$/.test(filename);
};
