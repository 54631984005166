import { appConfig } from '@/common/appConfig';
import { POLL_API_INTERVAL } from '@/common/constants';
import { useBiometricStore } from '@/store/biometricStore';
import { useConsultationStore } from '@/store/consultationStore';
import { useContractStore } from '@/store/contractStore';
import { useDocumentStore } from '@/store/documentStore';
import { useOnboardingStore } from '@/store/onboardingStore';
import { usePreferencesStore } from '@/store/preferencesStore';
import { usePrivacyStore } from '@/store/privacyStore';
import { useUserStore } from '@/store/userStore';
import { SSEName } from '@/types/sse-name.enum';
import { ref } from 'vue';

const initPreferencesStore = async () => {
	const preferencesStore = usePreferencesStore();
	await preferencesStore.initialize();

	preferencesStore.$subscribe(() => {
		preferencesStore.subscriber();
	});
};

const initOnboardingStore = async () => {
	useOnboardingStore().initStoreFromQueryParams();
};

const initPrivacyStore = async () => {
	const privacyStore = usePrivacyStore();
	await privacyStore.initialize();

	privacyStore.$subscribe(() => {
		privacyStore.subscriber();
	});
};

const initUserStore = async () => {
	return await useUserStore().initialize();
};

const initBiometricStore = async () => {
	return await useBiometricStore().initialize();
};

const initConsultationStore = async () => {
	return await useConsultationStore().initialize();
};

const initDocumentStore = async () => {
	return await useDocumentStore().initialize();
};

export const updateStoresPolling = async () => {
	const pollingIntervalId = ref();

	async function startPolling() {
		// const contractStore = useContractStore();
		const consultationStore = useConsultationStore();
		const pollingFunction = async () => {
			// await Promise.all([contractStore.fetchContracts(), consultationStore.fetchAppointments()]);
			await consultationStore.fetchAppointments();
		};
		pollingIntervalId.value = window.setInterval(pollingFunction, POLL_API_INTERVAL);
		window.addEventListener('beforeunload', stopPolling);
	}

	async function stopPolling() {
		if (pollingIntervalId.value !== undefined) {
			clearInterval(pollingIntervalId.value);
			pollingIntervalId.value = undefined;
		}
	}

	startPolling();
};

export const useSseListener = () => {
	if (appConfig.variables.environment === 'dev' && useUserStore().hasValidAccessToken) {
		import('@/api/sse').then((sseEvents) => {
			sseEvents.listenToSSE({
				url: appConfig.variables.sseUrl,
				onMessage(message) {
					const messageType: SSEName = JSON.parse(message.data).name;
					switch (messageType) {
						case SSEName.EMPLOYEE_CONTRACTS_UPDATED:
							console.log('sse: update contracts!');
							useContractStore().fetchContracts();
							break;
						case SSEName.EMPLOYEE_DOCUMENTS_UPDATED:
							console.log('sse: update documents!');
							useDocumentStore().loadDocuments();
							break;
					}
				},
				onError(error) {
					console.error(error);
				},
			});
		});
	}
};

export const initStores = async () => {
	await Promise.all([
		initPreferencesStore(),
		initOnboardingStore(),
		initPrivacyStore(),
		initUserStore(),
		initBiometricStore(),
		initConsultationStore(),
		initDocumentStore(),
	]);
	await updateStoresPolling();
	useSseListener();
};
