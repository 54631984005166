<script setup lang="ts">
import { ONBOARDING_STEP_COUNT, SIMPLE_LOCALES } from '@/common/constants';
import DLogo from '@/components/ui/DLogo.vue';
import DTertiaryButton from '@/components/ui/DTertiaryButton.vue';
import { RouteNames } from '@/router/routeNames';
import type { RouteName } from '@/router/routes';
import { usePreferencesStore } from '@/store/preferencesStore';
import { isOnboardingStep } from '@/types/onboarding-step.type';
import { storeToRefs } from 'pinia';
import { type Ref, onMounted, onUpdated, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const preferencesStore = usePreferencesStore();
const { simpleLanguage } = storeToRefs(preferencesStore);

const currentStep = ref(0);
const previousStep: Ref<RouteName | undefined> = ref(undefined);
const nextStep: Ref<RouteName | undefined> = ref(undefined);

const updateSteps = () => {
	currentStep.value = Number(route.meta.step);

	const prevStepString = `OnboardingStep${currentStep.value - 1}`;
	if (isOnboardingStep(prevStepString)) {
		previousStep.value = RouteNames[prevStepString];
	}

	const nextStepString = `OnboardingStep${currentStep.value + 1}`;
	if (isOnboardingStep(nextStepString)) {
		nextStep.value = RouteNames[nextStepString];
	}
};

onMounted(() => {
	updateSteps();
});

onUpdated(() => {
	updateSteps();
});
</script>

<template>
  <ion-header :translucent="true">
    <ion-toolbar>
      <ion-buttons v-if="Number(route.meta.step) === 1" slot="start">
        <ion-select v-model="simpleLanguage" interface="popover" data-cy="header_language_switch_select">
          <ion-select-option v-for="locale in SIMPLE_LOCALES" :key="`locale-${locale}`" :value="locale" :data-cy="`header_language_switch_option_${locale}`">
            {{ $t(`languages.${locale}-short`) }}
          </ion-select-option>
        </ion-select>
      </ion-buttons>
      <ion-title>
        <d-logo />
      </ion-title>
      <ion-buttons slot="end" v-if="currentStep < ONBOARDING_STEP_COUNT">
        <d-tertiary-button
          :router-link="{ name: RouteNames.OnboardingStep4 }"
          color="primary"
          data-cy="onboarding_header_skip_btn"
        >
          {{ $t('onboarding.skip') }}
        </d-tertiary-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<style scoped>
ion-toolbar {
  --background: rgba(var(--ion-color-light-rgb), 0.66);
  --padding-start: 20px;
  --padding-end: 13px;

  body[data-theme='dark'] & {
    --background: var(--ion-color-tertiary-light);
  }
}

ion-title {
  display: grid;
  place-content: center;
  font-size: 16px;
  line-height: 1;
}

ion-select {
	font-size: clamp(15px, 1rem, 22px);
}

ion-select::part(text),
ion-select::part(icon) {
  color: var(--ion-color-primary);
	font-size: clamp(15px, 1rem, 22px);
}
ion-select::part(icon) {
	width: 1em;
	height: 1em;
}
</style>

<style>
ion-popover {
	z-index: 70006 !important;
}
</style>
