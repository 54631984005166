import { getContractsForEmployee } from '@/api/contract';
import { useAppContractStatus } from '@/composables/useAppContractStatus';
import { useUserStore } from '@/store/userStore';
import type { AppContractStatus } from '@/types/contract-status.enum';
import { ContractType } from '@/types/contract-type.enum';
import type { Contract, ContractWithAppStatus } from '@/types/contract.interface';
import { defineStore } from 'pinia';
import { type ComputedRef, type Ref, computed, ref } from 'vue';

interface PersistedViewedContracts {
	[contractId: string]: AppContractStatus;
}

export const useContractStore = defineStore(
	'contract',
	() => {
		const rawContracts: Ref<Contract[]> = ref([]);
		const currentContract: Ref<ContractWithAppStatus | undefined> = ref();
		const viewedContracts: Ref<PersistedViewedContracts> = ref({});
		const showSignContractSuccessMessage = ref(false);

		const bavContracts: ComputedRef<ContractWithAppStatus[]> = computed(() => {
			if (!rawContracts.value.length) {
				return [];
			}
			return rawContracts.value
				.filter((contract) => {
					return contract.type === ContractType.BAV;
				})
				.map((contract) => {
					return useAppContractStatus(contract);
				});
		});

		async function fetchContracts() {
			if (useUserStore().hasValidAccessToken) {
				rawContracts.value = await getContractsForEmployee();
				if (!currentContract.value) {
					currentContract.value = bavContracts.value[0];
				} else {
					currentContract.value = bavContracts.value.filter((contract) => {
						return contract.id === currentContract.value?.id;
					})[0];
				}
			}
		}

		function markContractAsViewed(contract: ContractWithAppStatus) {
			viewedContracts.value[contract.id] = contract.status;
		}

		function unmarkContractAsViewed(contract: ContractWithAppStatus) {
			if (viewedContracts.value[contract.id]) {
				delete viewedContracts.value[contract.id];
			}
		}

		return {
			currentContract,
			bavContracts,
			viewedContracts,
			showSignContractSuccessMessage,

			fetchContracts,
			markContractAsViewed,
			unmarkContractAsViewed,
		};
	},
	{
		persist: {
			afterRestore() {
				useContractStore().fetchContracts();
			},
		},
	}
);
