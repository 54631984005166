import router from '@/router';
import { useAppStore } from '@/store/appStore';
import { useBiometricStore } from '@/store/biometricStore';
import { useOnboardingStore } from '@/store/onboardingStore';
import { useUserStore } from '@/store/userStore';
import { App, type URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';

export const addNativeAppStateListener = async () => {
	await SplashScreen.hide();

	App.addListener('resume', async () => {
		await initiateNativeAppState();
	});

	App.addListener('pause', async () => {
		useAppStore().setLastUsedNavigationState();
	});

	App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
		const url = new URL(data.url);
		const slug = url.pathname;
		const queryParams = Object.fromEntries(url.searchParams.entries());
		if (slug) {
			router.push({
				path: slug,
				query: queryParams,
			});
		}
	});
};

export const initiateNativeAppState = async () => {
	const appStore = useAppStore();
	const userStore = useUserStore();
	const biometricStore = useBiometricStore();
	appStore.init();
	useOnboardingStore().initStoreFromQueryParams();

	if (!biometricStore.isBiometricAuthEnabled) {
		return;
	}

	const verified = await biometricStore.verifyBiometricIdentity();

	if (!verified) {
		await userStore.logout();
		return;
	}

	await userStore.refreshLogin();
};
