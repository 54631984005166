export enum RouteNames {
	Index = 'Index',
	Onboarding = 'Onboarding',
	OnboardingStep1 = 'OnboardingStep1',
	OnboardingStep2 = 'OnboardingStep2',
	OnboardingStep3 = 'OnboardingStep3',
	OnboardingStep4 = 'OnboardingStep4',
	OnboardingWithPlaceholder = 'OnboardingWithPlaceholder',
	WillkommenWithPlaceholder = 'WillkommenWithPlaceholder',
	AuthTwoFactorSetup = 'AuthTwoFactorSetup',
	AuthSignIn = 'AuthSignIn',
	AuthForgotPassword = 'AuthForgotPassword',
	AuthResetPassword = 'AuthResetPassword',
	Dashboard = 'Dashboard',
	ContractDetail = 'ContractDetail',
	BAVIndex = 'Bav',
	BAVCalculator = 'BavCalculator',
	BAVResult = 'BavResult',
	RequestOfferIndex = 'RequestOfferIndex',
	RequestOfferFormPersonal = 'RequestOfferFormPersonal',
	ConsultationIndex = 'Consultation',
	ConsultationSummary = 'ConsultationSummary',
	Error = 'Error',
	Privacy = 'Privacy',
	Terms = 'Terms',
	Imprint = 'Imprint',
}
