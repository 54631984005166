import {
	ENVIRONMENT_DEVELOPMENT,
	ENVIRONMENT_PRODUCTION,
	ENVIRONMENT_STAGING,
	type ENVIRONMENT_TYPE,
	ENVIRONMENT_URL,
} from '@/types/environment';
import { Capacitor } from '@capacitor/core';

export const getEnvironment = (): ENVIRONMENT_TYPE => {
	return __BUILD_TARGET__ === 'native'
		? getEnvironmentForNativeDevices()
		: Capacitor.isNativePlatform()
			? getEnvironmentForNativeDevices()
			: getEnvironmentForWebbrowsers();
};

const getEnvironmentForNativeDevices = (): ENVIRONMENT_TYPE => {
	if (import.meta.env.MODE === 'production') {
		return ENVIRONMENT_PRODUCTION;
	}
	return ENVIRONMENT_STAGING;
	/*
	 *** staging check disabled to always use staging for native devices
	 * right now for better testing ***
	 */
	// if (import.meta.env.MODE === 'staging') {
	//   return ENVIRONMENT_STAGING;
	// }
	// return ENVIRONMENT_DEVELOPMENT;
};

const getEnvironmentForWebbrowsers = (): ENVIRONMENT_TYPE => {
	const currentHost = window.location.host;

	for (const environment in ENVIRONMENT_URL) {
		if (currentHost.includes(ENVIRONMENT_URL[environment as ENVIRONMENT_TYPE])) {
			return environment as ENVIRONMENT_TYPE;
		}
	}
	return ENVIRONMENT_DEVELOPMENT;
};
