import type { GetConsultantResponse } from '@/api/consultant/consultant.response';
import { appConfig } from '@/common/appConfig';
import { useUserStore } from '@/store/userStore';
import { ENVIRONMENT_PRODUCTION } from '@/types/environment';
import { dfetch } from '@/utils/fetchUtils';

const getConsultantForEmployee = async () => {
	return dfetch(`/consultants/employees/${useUserStore().id}`).catch(() => false);
};

export const returnFallbackWorkerId = () => {
	return appConfig.variables.environment === ENVIRONMENT_PRODUCTION ? 69543 : 73056;
};

export const getConsultantWorker = async (): Promise<GetConsultantResponse> => {
	const consultant = await getConsultantForEmployee();
	if (consultant === false) {
		return {
			workerId: returnFallbackWorkerId(),
			consultantData: {
				id: '',
				firstName: '',
				lastName: '',
			},
		};
	}

	const workerId = Number(new URL(consultant.appointmentSchedulingLink).searchParams.get('worker_id'));

	return {
		workerId: Number.isNaN(workerId) ? returnFallbackWorkerId() : workerId,
		consultantData: consultant,
	};
};
