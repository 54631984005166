import type { DocumentDownloadResponse } from '@/api/document/document-download.response';
import { appConfig } from '@/common/appConfig';
import { useContractStore } from '@/store/contractStore';
import { useUserStore } from '@/store/userStore';
import { DOCUMENT_API_TYPE_EMPLOYEE, type DocumentApiType } from '@/types/document-api-type';
import type { DocumentCategory } from '@/types/document-category.enum';
import { DocumentScope } from '@/types/document-scope.enum';
import type { DocumentUploadRequestDocument } from '@/types/document-upload.request';
import { downloadFileByUrl } from '@/utils/downloadUtils';
import { dfetch } from '@/utils/fetchUtils';

export const getDocumentsForEmployee = async (limit = 10, offset = 0) => {
	const userStore = useUserStore();
	return dfetch(`/documents/docs?employeeId=${userStore.id}&limit=${limit}&offset=${offset}`);
};

export const getDocumentsForContract = async (limit = 10, offset = 0) => {
	const contractStore = useContractStore();
	if (contractStore.currentContract === undefined) {
		throw new Error('Unable to fetch documents for contract if no contract is selected');
	}
	return dfetch(`/documents/docs?contractId=${contractStore.currentContract.id}&limit=${limit}&offset=${offset}`);
};

export const fetchDocuments = async (
	currentPage: number,
	perPage: number,
	by: DocumentApiType = DOCUMENT_API_TYPE_EMPLOYEE
) => {
	const offset = currentPage === 1 ? 0 : (currentPage - 1) * perPage;

	return by === DOCUMENT_API_TYPE_EMPLOYEE
		? await getDocumentsForEmployee(perPage, offset)
		: await getDocumentsForContract(perPage, offset);
};

export const downloadDocument = async (id: string) => {
	return downloadFileByUrl(await getDownloadDownloadUrl(id));
};

export const getDownloadDownloadUrl = async (id: string): Promise<string> => {
	const response: DocumentDownloadResponse = await dfetch(`/documents/docs/${id}/download`);
	return response.uri;
};

export const uploadDocument = async (
	files: FileList,
	documentName: string,
	category: DocumentCategory,
	contractId: string | undefined = undefined
) => {
	if (files.length === 0) {
		throw new Error('No file was attached to upload.');
	}
	const file = files[0];

	if (!file) {
		throw new Error('No valid file was attached to upload.');
	}

	const requestDocument: DocumentUploadRequestDocument = {
		name: documentName,
		category: category,
		scope: DocumentScope.EMPLOYEE,
		salesforceOnly: false,
		employeeId: useUserStore().id,
		employeeOnly: false,
		contractId: contractId,
	};

	const formData = new FormData();
	formData.append('file', file);
	formData.append('document', JSON.stringify(requestDocument));

	return dfetch(`${appConfig.variables.apiBaseUrl}/documents/docs`, {
		method: 'POST',
		body: formData,
	});
};

export const archiveDocument = async (documentId: string) => {
	return dfetch(`/documents/docs/${documentId}`, {
		method: 'PATCH',
		body: {
			status: 'ARCHIVED',
		},
	});
};
