import { SupportedLanguage, transformLanguage } from '@/types/language.enum';
import { type DefineDateTimeFormat, type DefineNumberFormat, type I18n, type I18nOptions, createI18n } from 'vue-i18n';

const defaultLocale: SupportedLanguage = SupportedLanguage.DE;
const fallbackLocale = {
	[SupportedLanguage.DEFORMAL]: [SupportedLanguage.DE],
	default: [SupportedLanguage.DE, SupportedLanguage.EN],
};
const deDatetimeFormats: DefineDateTimeFormat = {
	short: {
		year: '2-digit',
		month: 'numeric',
		day: '2-digit',
		timeZone: 'Europe/Berlin',
	},
	long: {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timeZone: 'Europe/Berlin',
	},
};

const deNumberFormats: DefineNumberFormat = {
	currency: {
		style: 'currency',
		currency: 'EUR',
		notation: 'standard',
		useGrouping: true,
		currencyDisplay: 'code',
	},
	decimal: {
		style: 'decimal',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	},
	percent: {
		style: 'percent',
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	},
};

const getLocaleMessages = () => {
	const locales = new Map([
		[SupportedLanguage.EN, {}],
		[SupportedLanguage.DE, {}],
		[SupportedLanguage.DEFORMAL, {}],
	]);
	const localeFiles = import.meta.glob('@/locales/*/*.json', {
		eager: true,
		import: 'default',
	});

	Object.entries(localeFiles).reduce((locales, [key, value]) => {
		const match = key.match(/\/([^/]+)\/([^/]+)\.json$/);
		const language = match ? (match[1] !== undefined ? transformLanguage(match[1]) : undefined) : undefined;
		const namespace = match ? (match[2] !== undefined ? match[2] : '') : '';

		if (language && locales.has(language)) {
			locales.set(language, { ...locales.get(language), [namespace]: value });
		}

		return locales;
	}, locales);

	return Object.fromEntries(locales);
};

const i18nOptions: I18nOptions = {
	globalInjection: true,
	legacy: false,
	locale: defaultLocale,
	fallbackLocale: fallbackLocale,
	messages: Object.assign(getLocaleMessages()),
	datetimeFormats: {
		en: {
			short: {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
				timeZone: 'Europe/Berlin',
			},
			long: {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				timeZone: 'Europe/Berlin',
			},
		},
		de: deDatetimeFormats,
		'de-formal': deDatetimeFormats,
	},
	numberFormats: {
		en: {
			currency: {
				style: 'currency',
				currency: 'EUR',
				notation: 'standard',
				currencyDisplay: 'code',
			},
			decimal: {
				style: 'decimal',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			},
			percent: {
				style: 'percent',
				minimumFractionDigits: 0,
				maximumFractionDigits: 2,
			},
		},
		de: deNumberFormats,
		'de-formal': deNumberFormats,
	},
};

export const languageUtils: I18n = createI18n<false, typeof i18nOptions>(i18nOptions);
