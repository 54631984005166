import { DOMAIN, TIME_TO_VERIFY_BIOMETRIC_AUTH } from '@/common/constants';
import { useUserStore } from '@/store/userStore';
import { BiometryTypeGroup } from '@/types/biometry.enum';
import { isMobileDevice } from '@/utils/helperUtils';
import { languageUtils } from '@/utils/languageUtils';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';
import { BiometryType } from '@capgo/capacitor-native-biometric/dist/esm/definitions';
import { defineStore } from 'pinia';
import { type Ref, ref, shallowReadonly } from 'vue';
import type { ComposerTranslation } from 'vue-i18n';

const { t }: { t: ComposerTranslation } = languageUtils.global;

export const useBiometricStore = defineStore(
	'biometricStore',
	() => {
		const lastBiometricVerificationInstant = ref(0);
		const isBiometricAuthAvailable: Ref<boolean> = ref(false);
		const isBiometricAuthEnabled: Ref<boolean> = ref(false);
		const biometricAuthType: Ref<BiometryType> = ref(0);

		async function initialize() {
			await checkBiometricAuthAvailability();
		}

		async function checkBiometricAuthAvailability() {
			if (!isMobileDevice() || useUserStore().mobilePhone2FA) {
				isBiometricAuthAvailable.value = false;
				isBiometricAuthEnabled.value = false;
				return false;
			}
			const isAvailable = await NativeBiometric.isAvailable({
				useFallback: true,
			});
			if (isAvailable.isAvailable) {
				isBiometricAuthAvailable.value = true;
				biometricAuthType.value = isAvailable.biometryType;
				return true;
			}
			return false;
		}

		function touchLastBiometricVerificationInstant() {
			lastBiometricVerificationInstant.value = Date.now();
		}

		async function enableBiometricAuth() {
			if (!isBiometricAuthAvailable.value) {
				return false;
			}
			isBiometricAuthEnabled.value = await verifyBiometricIdentity();

			return isBiometricAuthEnabled.value;
		}

		async function disableBiometricAuth(deleteBiometricAuth = false) {
			isBiometricAuthEnabled.value = false;
			lastBiometricVerificationInstant.value = 0;
			if (deleteBiometricAuth) {
				await deleteBiometricAuthData();
			}
		}

		async function verifyBiometricIdentity(forceNewVerification = false): Promise<boolean> {
			if (!isBiometricAuthAvailable.value) {
				return false;
			}

			if (
				!forceNewVerification &&
				lastBiometricVerificationInstant.value > 0 &&
				Date.now() - lastBiometricVerificationInstant.value < TIME_TO_VERIFY_BIOMETRIC_AUTH
			) {
				return true;
			}

			const verified = await NativeBiometric.verifyIdentity({
				reason: t('biometric.verifyIdentity.reason'),
				title: t('biometric.verifyIdentity.title'),
				negativeButtonText: t('biometric.verifyIdentity.negativeButtonText'),
				useFallback: true,
			})
				.then(() => true)
				.catch(() => false);

			if (verified) {
				touchLastBiometricVerificationInstant();
			}
			return verified;
		}

		async function saveBiometricAuthData({ username, password }: { username: string; password: string }) {
			if (!isBiometricAuthAvailable.value) {
				throw new Error('Biometric auth is not available.');
			}
			await NativeBiometric.setCredentials({
				username: username,
				password: password,
				server: DOMAIN,
			});
		}

		async function getBiometricAuthData() {
			if (!isBiometricAuthAvailable.value) {
				throw new Error('Biometric auth is not available.');
			}
			return await NativeBiometric.getCredentials({
				server: DOMAIN,
			});
		}

		async function updateBiometricAuthData({ username = '', password }: { username?: string; password: string }) {
			if (!isBiometricAuthAvailable.value) {
				throw new Error('Biometric auth is not available.');
			}
			if (!username || username === '') {
				const currentlyStoredData = await getBiometricAuthData();
				username = currentlyStoredData.username;
			}

			// first delete old data
			await deleteBiometricAuthData();

			// then store new data
			await saveBiometricAuthData({ username, password });
		}

		async function deleteBiometricAuthData() {
			if (!isBiometricAuthAvailable.value) {
				return;
			}
			await NativeBiometric.deleteCredentials({
				server: DOMAIN,
			});
		}

		function getBiometricAuthTypeGroup() {
			return [
				BiometryType.FACE_ID,
				BiometryType.FACE_AUTHENTICATION,
				BiometryType.IRIS_AUTHENTICATION,
				BiometryType.MULTIPLE,
			].includes(biometricAuthType.value)
				? BiometryTypeGroup.FACE
				: BiometryTypeGroup.FINGERPRINT;
		}

		return {
			lastBiometricVerificationInstant: shallowReadonly(lastBiometricVerificationInstant),
			isBiometricAuthAvailable: shallowReadonly(isBiometricAuthAvailable),
			isBiometricAuthEnabled: isBiometricAuthEnabled,
			biometricAuthType: shallowReadonly(biometricAuthType),

			initialize,

			enableBiometricAuth,
			disableBiometricAuth,

			verifyBiometricIdentity,

			getBiometricAuthData,
			updateBiometricAuthData,
			deleteBiometricAuthData,

			getBiometricAuthTypeGroup,
		};
	},
	{
		persist: true,
	}
);
