import { getConsultantWorker } from '@/api/consultant';
import { getAppointmentsForEmployee } from '@/api/salesforce';
import { CONSULTATION_DATE_SOURCES, type ConsultationDateSource } from '@/common/constants';
import { useUserStore } from '@/store/userStore';
import { getCalensoLinkFromId } from '@/utils/calensoLinkUtils';
import { defineStore } from 'pinia';
import { type ComputedRef, type Ref, computed, ref } from 'vue';

export interface ConsultantProfile {
	id: string;
	firstName: string;
	lastName: string;
	gender: 'MALE' | 'FEMALE' | 'OTHER';
	appointmentSchedulingLink: string;
	profilePicture: string;
	phoneNumber: string;
}

export interface ConsultationDate {
	date: Date;
	consultantProfile?: ConsultantProfile;
	source: ConsultationDateSource;
	bookingUrl?: string;
	meetingUrl?: string;
}

export interface SalesforceConsultationDate {
	scheduledFor: Date;
	meetingLink: string | null;
	calensoAppointmentId: string;
}

export const useConsultationStore = defineStore(
	'consultation',
	() => {
		const tempConsultation: Ref<ConsultationDate | undefined> = ref();
		const fetchedConsultations: Ref<SalesforceConsultationDate[]> = ref([]);
		const consultantProfile = ref();

		const consultationChecklist = ref([
			{
				id: 'item1',
				translationKey: 'consultation.summary.checklist.item1',
				checked: false,
			},
			{
				id: 'item2',
				translationKey: 'consultation.summary.checklist.item2',
				checked: false,
			},
		]);

		const upcomingConsultations: ComputedRef<ConsultationDate[]> = computed(() => {
			if (!fetchedConsultations.value || fetchedConsultations.value.length === 0) {
				return [];
			}
			return fetchedConsultations.value.map((consultation) => {
				return {
					date: new Date(consultation.scheduledFor),
					source: CONSULTATION_DATE_SOURCES.CALENSO,
					bookingUrl: getCalensoLinkFromId(consultation.calensoAppointmentId),
					meetingUrl: consultation.meetingLink,
				} as ConsultationDate;
			});
		});

		const nextConsultation: ComputedRef<ConsultationDate | undefined> = computed(() => {
			if (upcomingConsultations.value.length) {
				return {
					...upcomingConsultations.value[0],
					consultantProfile: consultantProfile.value,
				} as ConsultationDate;
			}

			if (tempConsultation.value) {
				tempConsultation.value.consultantProfile = consultantProfile.value;
				return tempConsultation.value;
			}
			return undefined;
		});

		function $reset() {
			tempConsultation.value = undefined;
		}

		function addConsultation(
			date: Date,
			source: ConsultationDateSource,
			profile: ConsultantProfile,
			bookingUrl?: string
		) {
			consultantProfile.value = profile;
			tempConsultation.value = { date, source, bookingUrl } as ConsultationDate;
			setTimeout(() => {
				fetchAppointments();
			}, 15_000);
		}

		async function fetchAppointments() {
			const userStore = useUserStore();

			if (userStore.hasValidAccessToken) {
				fetchedConsultations.value = await getAppointmentsForEmployee();
				if (fetchedConsultations.value && fetchedConsultations.value.length > 0) {
					$reset();
				}
			}
		}

		async function initialize() {
			if (!consultantProfile.value && useUserStore().hasValidAccessToken) {
				consultantProfile.value = await getConsultantWorker();
			}
		}

		return {
			consultationChecklist,

			nextConsultation,
			upcomingConsultations,

			$reset,
			initialize,
			addConsultation,
			fetchAppointments,
		};
	},
	{
		persist: {
			afterRestore() {
				useConsultationStore().initialize();
			},
		},
	}
);
