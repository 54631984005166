<script setup lang="ts">
import DFullscreenSuccess from '@/components/ui/DFullscreenSuccess.vue';
import { RouteNames } from '@/router/routeNames';
import { initStores } from '@/store';
import { useAppStore } from '@/store/appStore';
import { usePrivacyStore } from '@/store/privacyStore';
import { initiateNativeAppState } from '@/utils/nativeAppUtils';
import OnboardingFooter from '@/views/onboarding/partials/OnboardingFooter.vue';
import OnboardingHeader from '@/views/onboarding/partials/OnboardingHeader.vue';
import { Capacitor } from '@capacitor/core';
import { Device, type DeviceInfo } from '@capacitor/device';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const appStore = useAppStore();
const privacyStore = usePrivacyStore();
const { displayFullscreenSuccess } = storeToRefs(appStore);
const route = useRoute();
const onboardingRoutes = [
	RouteNames.OnboardingStep1,
	RouteNames.OnboardingStep2,
	RouteNames.OnboardingStep3,
	RouteNames.OnboardingStep4,
];

const animatePageTransitions = computed(() => {
	if (Capacitor.isNativePlatform()) {
		return true;
	}
	Device.getInfo()
		.then((deviceInfo: DeviceInfo) => {
			return deviceInfo.operatingSystem === 'ios' || deviceInfo.operatingSystem === 'android';
		})
		.catch(() => {
			return true;
		});
});

// Compute whether the current route is an onboarding route
const isOnboardingRoute = computed(() => (route.name ? onboardingRoutes.includes(route.name as RouteNames) : false));

onMounted(async () => {
	await initStores();
	await initiateNativeAppState();
});
</script>
<template>
	<ion-app>
		<d-fullscreen-success v-if="displayFullscreenSuccess" />
		<onboarding-header v-if="isOnboardingRoute" />
		<ion-router-outlet id="main-content" :animated="animatePageTransitions" />
		<onboarding-footer id="onboarding-footer" v-if="isOnboardingRoute" />
		<ion-toast v-if="!privacyStore.cookieAllowed" :header="$t('privacy.cookieToast.header')"
			:message="$t('privacy.cookieToast.message')" :is-open="!privacyStore.cookieAllowed"
			css-class="d-toast d-info-toast backdrop" icon="/assets/d-info.svg" layout="stacked" :html-attributes="{
			'data-cy': 'consent_window_toast'
		}" :buttons="[
			{
				text: $t('privacy.cookieToast.cancelButton'),
				handler: () => {
					privacyStore.cookieAllowed = true;
					privacyStore.allowPiwikPro = false;
				},
				cssClass: 'cancel-button',
				role: 'reject-cookies',
				htmlAttributes: { 'part': 'cancel button' }
			},
			{
				text: $t('privacy.cookieToast.acceptButton'),
				handler: () => {
					privacyStore.cookieAllowed = true;
					privacyStore.allowPiwikPro = true;
				},
				role: 'allow-cookies'
			},
		]" />

	</ion-app>
</template>
