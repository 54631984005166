import { usePreferencesStore } from '@/store/preferencesStore';
import { usePrivacyStore } from '@/store/privacyStore';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

export const getObjectKeyByValue = (obj: { [key: string]: any }, targetValue: any): string | null => {
	for (const key in obj) {
		if (obj.hasOwn(key) && obj[key] === targetValue) {
			return key;
		}
	}
	return null;
};

export function isMobileDevice(): boolean {
	return Capacitor.getPlatform() !== 'web';
}

export async function isMobileDeviceInWeb() {
	const deviceInfo = await Device.getInfo();
	return (
		(deviceInfo.operatingSystem === 'ios' || deviceInfo.operatingSystem === 'android') && deviceInfo.platform === 'web'
	);
}

export function convertObjectToSearchParams(obj: any) {
	const searchParams = new URLSearchParams();
	for (const key in obj) {
		searchParams.set(key, obj[key]);
	}
	return searchParams;
}

export async function openLink(link: string, addPreferencesQueries = false) {
	let browserUrl: string = link;
	if (addPreferencesQueries) {
		browserUrl = `${link}?${convertObjectToSearchParams(usePreferencesStore().$state).toString()}&${convertObjectToSearchParams(usePrivacyStore().$state).toString()}`;
	}
	await Browser.open({
		url: browserUrl,
		presentationStyle: 'popover',
	});
}
