import { getCompany } from '@/api/company';
import { appConfig } from '@/common/appConfig';
import { PREFERS_DARK_THEME } from '@/common/constants';
import { useUserStore } from '@/store/userStore';
import { SupportedLanguage, transformLanguage } from '@/types/language.enum';
import { Theme } from '@/types/theme.enum';
import { languageUtils } from '@/utils/languageUtils';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { defineStore } from 'pinia';
import { type ComputedRef, type Ref, type WritableComputedRef, computed, ref } from 'vue';

interface PreferencesOptions {
	chosenLanguage: SupportedLanguage | null;
	useAutomaticThemeSwitch: boolean;
	preferLightTheme: boolean;
}

export const usePreferencesStore = defineStore('preferences', () => {
	const chosenLanguage: Ref<SupportedLanguage | null> = ref(null);
	const useAutomaticThemeSwitch = ref(true);
	const activeTheme: Ref<Theme> = ref(Theme.LIGHT);
	const preferLightTheme: Ref<boolean> = ref(true);

	// Getters
	const $state: ComputedRef<PreferencesOptions> = computed(
		() =>
			({
				chosenLanguage: chosenLanguage.value,
				useAutomaticThemeSwitch: useAutomaticThemeSwitch.value,
				activeTheme: activeTheme.value,
				preferLightTheme: preferLightTheme.value,
			}) as PreferencesOptions
	);

	const getSimpleLanguage = computed(() => {
		if (chosenLanguage.value === SupportedLanguage.EN) {
			return SupportedLanguage.EN;
		}
		// ignore formal de
		return SupportedLanguage.DE;
	});

	const simpleLanguage = computed({
		get() {
			return getSimpleLanguage.value;
		},
		async set(value) {
			await setChosenLanguage(value);
		},
	});

	// Actions
	function applyTheme() {
		if (useAutomaticThemeSwitch.value) {
			watchPreferredColor();
			return;
		}
		unwatchPreferredColor();
		setThemeAttribute(!preferLightTheme.value);
	}

	function setThemeAttribute(prefersDark: boolean) {
		activeTheme.value = prefersDark ? Theme.DARK : Theme.LIGHT;
		document.body.dataset.theme = activeTheme.value;
	}

	const handleMediaThemeChange = (matchMedia: MediaQueryListEvent) => setThemeAttribute(matchMedia.matches);

	function watchPreferredColor() {
		setThemeAttribute(PREFERS_DARK_THEME.matches);
		PREFERS_DARK_THEME.addEventListener('change', handleMediaThemeChange);
	}

	function unwatchPreferredColor() {
		PREFERS_DARK_THEME.removeEventListener('change', handleMediaThemeChange);
	}

	async function loadLanguageFromDevice() {
		if (chosenLanguage.value === null) {
			const deviceLanguageCode = await Device.getLanguageCode();
			chosenLanguage.value = transformLanguage(deviceLanguageCode.value);
		}
	}

	async function setChosenLanguage(locale: SupportedLanguage | null) {
		if (locale === SupportedLanguage.DE) {
			if (useUserStore().companyId) {
				const company = await getCompany();
				chosenLanguage.value =
					company.languagePreference.form === 'LANGUAGE_INFORMAL' ? SupportedLanguage.DE : SupportedLanguage.DEFORMAL;
			} else {
				chosenLanguage.value = appConfig.variables.defaultLanguage;
			}
		} else {
			chosenLanguage.value = SupportedLanguage.EN;
		}
	}

	async function loadStateFromNativeStore() {
		const { value } = await Preferences.get({ key: 'preferences' });
		if (value) {
			const storedPreferences = JSON.parse(value);
			chosenLanguage.value = storedPreferences.chosenLanguage;
			useAutomaticThemeSwitch.value = storedPreferences.useAutomaticThemeSwitch;
			preferLightTheme.value = storedPreferences.preferLightTheme;
		}
	}

	async function saveStateToNativeStorage() {
		await Preferences.set({
			key: 'preferences',
			value: JSON.stringify($state.value),
		});
	}

	function saveLanguageToI18n() {
		if (chosenLanguage.value !== null) {
			(languageUtils.global.locale as WritableComputedRef<string>).value = chosenLanguage.value;
			document.documentElement.lang = chosenLanguage.value.substring(0, 2);
		}
	}

	function getPreferencesFromQueryParams() {
		const queryParams = Object.fromEntries(new URL(window.location.href).searchParams.entries());
		if (queryParams.chosenLanguage) {
			chosenLanguage.value = transformLanguage(queryParams.chosenLanguage);
		}
		if (queryParams.useAutomaticThemeSwitch) {
			useAutomaticThemeSwitch.value = queryParams.useAutomaticThemeSwitch === 'true';
		}
		if (queryParams.preferLightTheme) {
			preferLightTheme.value = queryParams.preferLightTheme === 'true';
		}
	}

	async function initialize() {
		await loadStateFromNativeStore();
		getPreferencesFromQueryParams();
		await loadLanguageFromDevice();
		applyTheme();
		saveLanguageToI18n();
	}

	async function subscriber() {
		saveStateToNativeStorage();
		applyTheme();
		saveLanguageToI18n();
	}

	return {
		chosenLanguage,
		simpleLanguage,
		useAutomaticThemeSwitch,
		activeTheme,
		preferLightTheme,

		getSimpleLanguage,

		initialize,
		subscriber,
	};
});
