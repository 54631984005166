<script setup lang="ts">
import DeguraLogoDark from '@/assets/degura-logo-dark.svg';
import DeguraLogo from '@/assets/degura-logo.svg';
import { usePreferencesStore } from '@/store/preferencesStore';
import { storeToRefs } from 'pinia';

const { activeTheme } = storeToRefs(usePreferencesStore());
</script>

<template>
  <degura-logo v-if="activeTheme === 'light'" />
  <degura-logo-dark v-else />
</template>
<style scoped>
svg {
  height: 16px;
}
</style>
