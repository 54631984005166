export enum HeaderName {
	AUTHORIZATION = 'authorization',
	X_API_KEY = 'x-api-key',
	X_AUTH_REQUEST_REDIRECT = 'x-auth-request-redirect',
	CURRENT_USER_ID = 'current-user-id',
	CURRENT_USER_ROLES = 'current-user-roles',
	CURRENT_USER_TOKEN = 'current-user-token',
	CURRENT_COMPANY_ID = 'current-company-id',
	CURRENT_ORGANIZATION_ID = 'current-organization-id',
	CURRENT_COMPANIES_ASSIGNED = 'current-companies-assigned',
}
