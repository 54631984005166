export const DATE_FORMAT = 'DD.MM.YYYY' as const;
export const TIMEZONE = 'Europe/Berlin' as const;

export const POLL_API_INTERVAL = 60_000 as const; // 1 minute: 1000 + 60

export const REGULAR_RETIREMENT_AGE = 67 as const;

export const ONBOARDING_STEP_COUNT = 4 as const;
export const PREFERS_DARK_THEME: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

export const PROTOCOL = 'https://' as const;
export const DOMAIN = 'app.degura.de' as const;

export const TIME_TO_VERIFY_BIOMETRIC_AUTH = 900_000 as const; // 15 minutes: 1000 * 60 * 15

export const SUCCESS_DURATION = 1750 as const;
export const TOAST_DURATION = 1750 as const;

export enum LOGIN_RESPONSE_STATUS {
	ERROR = 0,
	SUCCESS = 1,
	TFA = 2,
}

export const LOGIN_METHODS = {
	EMAIL: 'email',
	GOOGLE: 'google',
} as const;

export type LoginMethod = (typeof LOGIN_METHODS)[keyof typeof LOGIN_METHODS];

export const BAV_GROSS_INCOME_RANGE = {
	MIN: 20_000,
	MAX: 90_600,
} as const;

export const BAV_CONTRIBUTION_RANGE = {
	MIN: 20,
	MAX: 600,
} as const;

export const CONSULTATION_DATE_SOURCES = {
	APP: 'app',
	CALENSO: 'calenso',
} as const;

export const SIMPLE_LOCALES = ['de', 'en'] as const;

export type ConsultationDateSource = (typeof CONSULTATION_DATE_SOURCES)[keyof typeof CONSULTATION_DATE_SOURCES];
