import type { GetCompanyInfoResponse, GetCompanyResponse } from '@/api/company/getCompanyResponse';
import { useUserStore } from '@/store/userStore';
import { dfetch } from '@/utils/fetchUtils';

export const getCompany = async (): Promise<GetCompanyResponse> => {
	const companyId = useUserStore().companyId;
	return dfetch(`/companies/${companyId}`);
};

export const getCompanyInfo = async (): Promise<GetCompanyInfoResponse> => {
	const companyId = useUserStore().companyId;

	return dfetch(`/onboarding/company/${companyId}`);
};

export const isCompanyEnforcing2FA = async (): Promise<boolean> => {
	const company = await getCompany();
	return company.settings2fa === 'FORCE';
};
