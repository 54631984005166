import { type LoginMethod, SUCCESS_DURATION } from '@/common/constants';
import router from '@/router';
import type { RouteName } from '@/router/routes';
import { formatDate } from '@/utils/dateUtils';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { defineStore } from 'pinia';
import { type Ref, ref } from 'vue';

export const useAppStore = defineStore(
	'app',
	() => {
		const isFirstRun: Ref<boolean> = ref(true);
		const lastNavigationState: Ref<RouteName | undefined> = ref();
		const version = ref();
		const lastUsedLoginMethod: Ref<LoginMethod | undefined> = ref();
		const displayFullscreenSuccess = ref(false);

		function init() {
			version.value = `${__APP_VERSION__} (${formatDate(__BUILD_DATE__)})`;

			// commented out as this produced failures during testing / onboarding
			// if (
			//   lastNavigationState.value &&
			//   isMobileDevice() &&
			//   lastNavigationState.value !== router.currentRoute.value.name
			// ) {
			//   router.replace({ name: lastNavigationState.value });
			// }
		}

		function touchFirstRun() {
			isFirstRun.value = false;
		}

		function setLastUsedLoginMethod(loginMethod: LoginMethod) {
			lastUsedLoginMethod.value = loginMethod;
		}

		function setLastUsedNavigationState() {
			lastNavigationState.value = router.currentRoute.value.name as RouteName;
		}

		function showSuccess(callback?: () => void, duration: number = SUCCESS_DURATION) {
			displayFullscreenSuccess.value = true;
			Haptics.notification({
				type: NotificationType.Success,
			});

			setTimeout(() => {
				callback?.();
				displayFullscreenSuccess.value = false;
			}, duration);
		}

		return {
			isFirstRun,
			lastNavigationState,
			version,
			lastUsedLoginMethod,
			displayFullscreenSuccess,

			init,
			touchFirstRun,
			setLastUsedLoginMethod,
			setLastUsedNavigationState,
			showSuccess,
		};
	},
	{
		persist: true,
	}
);
