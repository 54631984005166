import type { GetMeResponse, LoginResponseData } from '@/api/authentication/login.response';
import { useUserStore } from '@/store/userStore';
import { dfetch } from '@/utils/fetchUtils';

export const userLogin = async (
	loginId: string | null,
	password: string | null,
	oneTimePassword: string | null = null
): Promise<LoginResponseData | false> => {
	return dfetch('/authentication/login', {
		method: 'POST',
		body: {
			loginId,
			password,
			oneTimePassword,
		},
	}).catch(() => false);
};

export const getMe = async (): Promise<GetMeResponse> => {
	return dfetch('/authentication/user/me');
};

export const changePassword = async (currentPassword: string, password: string) => {
	return dfetch('/authentication/user/change-password', {
		method: 'POST',
		body: {
			currentPassword,
			password,
		},
	}).catch(() => false);
};

export const forgotPassword = async (loginId: string) => {
	return dfetch('/authentication/user/forgot-password', {
		method: 'POST',
		body: {
			loginId,
		},
	});
};

export const resetPassword = async (token: string, password: string) => {
	return dfetch('/authentication/user/change-password-required', {
		method: 'POST',
		body: {
			changePasswordId: token,
			password: password,
		},
	}).catch(() => false);
};

export const sendTwoFactorConfirmCode = async (mobilePhone: string, method = 'sms') => {
	return dfetch('/authentication/2fa/send-confirm-code', {
		method: 'POST',
		body: {
			mobilePhone,
			method,
		},
	});
};

const generateTwoFactorSecret = async () => {
	return dfetch('/authentication/2fa/secret');
};

export const confirmTwoFactor = async (code: string, mobilePhone: string) => {
	const secret = await generateTwoFactorSecret();

	return dfetch('/authentication/2fa/confirm', {
		method: 'POST',
		body: {
			code,
			mobilePhone,
			secretBase32Encoded: secret.secretBase32Encoded,
			method: 'sms',
		},
	}).catch(() => false);
};

const getRecoveryCodes = async () => {
	return dfetch('/authentication/2fa/recovery-codes');
};

export const remove2faMethod = async () => {
	const recoveryCodes = await getRecoveryCodes();
	const userStore = useUserStore();

	return dfetch(
		`/authentication/2fa?userId=${userStore.id}&code=${recoveryCodes[0]}&methodId=${userStore.twoFactor.methods[0].id}`,
		{
			method: 'DELETE',
		}
	);
};

export const sendTwoFactorAuthCode = async (twoFactorId: string, methodId: string) => {
	return dfetch('/authentication/2fa/send', {
		method: 'POST',
		body: {
			twoFactorId,
			methodId,
		},
	});
};

export const loginWithTwoFactorCode = async (twoFactorId: string, code: string): Promise<LoginResponseData | false> => {
	return dfetch('/authentication/2fa/login', {
		method: 'POST',
		body: {
			twoFactorId,
			code,
		},
	}).catch(() => false);
};

export const loginWithExternalIdentityProvider = async (
	clientId: string,
	token: string
): Promise<LoginResponseData | false> => {
	return dfetch('/authentication/identity-provider/login', {
		method: 'POST',
		body: {
			identityProviderId: '82339786-3dff-42a6-aac6-1f1ceecb6c46',
			token: token,
		},
	}).catch(() => false);
};
