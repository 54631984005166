export enum DocumentCategory {
	DOC_OFFER = 'DOC_OFFER',
	DOC_PROCESSING_AGREEMENT = 'DOC_PROCESSING_AGREEMENT',
	DOC_FORM = 'DOC_FORM',
	DOC_CONSUMER_INFO = 'DOC_CONSUMER_INFO',
	DOC_CLIENT_INFO = 'DOC_CLIENT_INFO',
	DOC_CONVERSION_AGREEMENT = 'DOC_CONVERSION_AGREEMENT',
	DOC_OTHER = 'DOC_OTHER',
	DOC_REQUEST = 'DOC_REQUEST',
	DOC_CUSTOMER_INFO = 'DOC_CUSTOMER_INFO',
	DOC_CHECKLIST = 'DOC_CHECKLIST',
	DOC_WAIVER = 'DOC_WAIVER',
	DOC_MANDATE = 'DOC_MANDATE',
	DOC_POLICY = 'DOC_POLICY',
	DOC_CONSULTATION = 'DOC_CONSULTATION',
	DOC_BALANCE_REPORT = 'DOC_BALANCE_REPORT',
	DOC_PENSION_SCHEME = 'DOC_PENSION_SCHEME',
	DOC_INCIDENT = 'DOC_INCIDENT',
	DOC_COLLECTIVE_AGREEMENT = 'DOC_COLLECTIVE_AGREEMENT',
	DOC_LIST_REPORT = 'DOC_LIST_REPORT',
	DOC_PRODUCT_INFO = 'DOC_PRODUCT_INFO',
	DOC_TERMS = 'DOC_TERMS',
}
