export const ENVIRONMENT_DEVELOPMENT = 'dev' as const;
export const ENVIRONMENT_STAGING = 'staging' as const;
export const ENVIRONMENT_PRODUCTION = 'prod' as const;

export const ALL_ENVIRONMENTS = [ENVIRONMENT_DEVELOPMENT, ENVIRONMENT_STAGING, ENVIRONMENT_PRODUCTION] as const;

export type ENVIRONMENT_TYPE = (typeof ALL_ENVIRONMENTS)[number];

export const ENVIRONMENT_URL: Record<ENVIRONMENT_TYPE, string> = {
	[ENVIRONMENT_DEVELOPMENT]: 'app.develop.degura.de',
	[ENVIRONMENT_STAGING]: 'app.staging.degura.de',
	[ENVIRONMENT_PRODUCTION]: 'app.degura.de',
} as const;

export const ENVIRONMENT_ENDPOINT = {
	[ENVIRONMENT_DEVELOPMENT]: {
		api: 'https://api.develop.degura.de/2.0',
		assets: 'https://assets.develop.degura.de',
		piwikContainerId: 'c72d2b2c-b43f-46ae-b0ce-2c795f9ec6df',
	},
	[ENVIRONMENT_STAGING]: {
		api: 'https://api.staging.degura.de/2.0',
		assets: 'https://assets.staging.degura.de',
		piwikContainerId: 'c36a8dc9-4f3f-49d0-af02-93326c45b393',
	},
	[ENVIRONMENT_PRODUCTION]: {
		api: 'https://api.degura.de/2.0',
		assets: 'https://assets.degura.de',
		piwikContainerId: 'd53f35bf-6a6b-482c-b237-1468dfa5c448',
	},
} as const;
