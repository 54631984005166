import { useUserStore } from '@/store/userStore';
import type { Contract } from '@/types/contract.interface';
import { dfetch } from '@/utils/fetchUtils';

export const getContractsForEmployee = async (): Promise<Contract[]> => {
	return dfetch(`/contracts/employees/${useUserStore().id}`);
};

export const employeeWaiveCompanyPension = async () => {
	const userStore = useUserStore();
	return dfetch(`/contracts/employees/${userStore.id}/opt-out`, {
		method: 'PUT',
		body: {
			emailPersonal: userStore?.personalData?.emailPersonal,
			phoneNumber: userStore?.personalData?.phoneNumber,
		},
	});
};
