import { appConfig } from '@/common/appConfig';
import { useUserStore } from '@/store/userStore';
import { ofetch } from 'ofetch';

export const dfetch = ofetch.create({
	baseURL: appConfig.variables.apiBaseUrl,
	async onRequest({ request, options }) {
		const userStore = useUserStore();

		if (!userStore.hasValidAccessToken) {
			return;
		}
		options.headers = {
			...options.headers,
			[appConfig.variables.apiAuthenticationHeader]: userStore.accessToken,
		};
	},
});
