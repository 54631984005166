const executeDownload = (href: string, download = '') => {
	const link = document.createElement('a');
	link.href = href;
	link.target = '_blank';
	link.download = download;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const downloadFileByUrl = (url: string) => {
	return executeDownload(url);
};
