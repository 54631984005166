import { useConsultationStore } from '@/store/consultationStore';
import { useDocumentStore } from '@/store/documentStore';
import {
	APP_CONTRACT_EXISTING,
	APP_CONTRACT_GET_YOUR_OFFER,
	APP_CONTRACT_IN_CONSULTATION,
	APP_CONTRACT_OFFER_RECEIVED,
	APP_CONTRACT_OFFER_REQUESTED,
	APP_CONTRACT_OPTED_OUT,
	APP_CONTRACT_SIGNED,
	APP_CONTRACT_SUSPENDED,
	APP_CONTRACT_WAITING_FOR_CONTRACT,
	type AppContractStatus,
	ContractStatus,
} from '@/types/contract-status.enum';
import type { Contract, ContractWithAppStatus } from '@/types/contract.interface';
import { sameDay } from '@formkit/tempo';

export const useAppContractStatus = (contract: Contract): ContractWithAppStatus => {
	const documentStore = useDocumentStore();
	const consultationStore = useConsultationStore();

	let status: AppContractStatus = APP_CONTRACT_GET_YOUR_OFFER;

	if (!contract.contractStatus) {
		return {
			...contract,
			status,
		} as ContractWithAppStatus;
	}
	switch (contract.contractStatus) {
		case ContractStatus.CONTRACT_ONBOARDED:
			status =
				consultationStore.nextConsultation && sameDay(consultationStore.nextConsultation.date, new Date())
					? APP_CONTRACT_IN_CONSULTATION
					: APP_CONTRACT_GET_YOUR_OFFER;
			break;
		case ContractStatus.CONTRACT_OFFER_REQUESTED:
			if (documentStore.getContractOffers.length > 0) {
				status = APP_CONTRACT_OFFER_RECEIVED;
				break;
			}
			status =
				contract.employeeContributionNet && contract.employeeContributionGross && contract.employerContribution
					? APP_CONTRACT_OFFER_REQUESTED
					: APP_CONTRACT_IN_CONSULTATION;
			break;
		case ContractStatus.CONTRACT_OFFER_ACCEPTED:
		case ContractStatus.CONTRACT_APPLICATION:
			status = APP_CONTRACT_WAITING_FOR_CONTRACT;
			break;
		case ContractStatus.CONTRACT_SIGNED:
			status = APP_CONTRACT_SIGNED;
			break;
		case ContractStatus.CONTRACT_EXISTING:
			status = APP_CONTRACT_EXISTING;
			break;
		case ContractStatus.CONTRACT_OPTED_OUT:
			status = APP_CONTRACT_OPTED_OUT;
			break;
		case ContractStatus.CONTRACT_SUSPENDED:
			status = APP_CONTRACT_SUSPENDED;
			break;
		default:
			break;
	}
	return {
		...contract,
		status,
	} as ContractWithAppStatus;
};
