import { isEmail, isValidFilename } from '@/common/validators';
import { languageUtils } from '@/utils/languageUtils';
import type { ComposerTranslation } from 'vue-i18n';
import * as yup from 'yup';

const { t }: { t: ComposerTranslation } = languageUtils.global;
const validPasswordRegexp = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*\d)+)(?=(.*[^a-zA-Z0-9])+)(?!.*\s).{8,}$/;

declare module 'yup' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface StringSchema<TType, TContext, TDefault, TFlags> {
		isValidPassword(): this;

		isEmail(): this;

		isValidFilename(): this;
	}
}

yup.addMethod(yup.string, 'isValidPassword', function () {
	return this.test({
		test: (password) => (password ? validPasswordRegexp.test(password) : false),
		message: t('forms.fields.passwordNew.hint'),
	});
});

yup.addMethod(yup.string, 'isEmail', function () {
	return this.test({
		test: (email) => (email ? isEmail(email) : false),
		message: t('forms.fields.email.hint'),
	});
});

yup.addMethod(yup.string, 'isValidFilename', function () {
	return this.test({
		test: (filename) => (filename ? isValidFilename(filename) : false),
		message: t('forms.fields.fileName.errorInvalid'),
	});
});
