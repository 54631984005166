<script setup lang="ts">
import DIcon from '@/components/ui/DIcon.vue';
import { type Ref, onMounted, ref } from 'vue';

const success: Ref<boolean> = ref(false);

onMounted(() => {
	setTimeout(() => {
		success.value = true;
	}, 1);
});
</script>

<template>
  <transition>
    <section id="successMessage" v-show="success" data-cy="success_screen">
      <d-icon name="success" color="primary" size="96px" />
    </section>
  </transition>
</template>

<style scoped>
.v-enter-active {
  &#successMessage {
    transition:
      all 0.75s ease-out,
      border-radius 0s linear 1s;

    .icon-wrapper {
      transition: all 0.3s ease-in 0s;
    }
  }
}

.v-leave-active {
  &#successMessage {
    transition:
      all 0.75s ease-out,
      border-radius 0s linear 0s;

    .icon-wrapper {
      transition: all 0.3s ease-in 0.5s;
    }
  }
}

.v-enter-to,
.v-leave-from {
  &#successMessage {
    --inset: -90%;
    --border-radius: 0%;
    --scale: 1;
    --opacity: 1;
  }
}

.v-enter-from,
.v-leave-to {
  &#successMessage {
    --border-radius: 100%;
    --inset: -30%;
    --scale: 0;
    --opacity: 0;
  }
}

#successMessage {
  --inset: -90%;
  --border-radius: 0%;
  --scale: 1;
  --opacity: 1;

  display: grid;
  position: absolute;
  place-content: center;
  transform: scale(var(--scale, 0)) translateZ(20002px);
  opacity: var(--opacity);
  z-index: 90003;
  transition:
    all 0.75s ease-out,
    border-radius 0s linear 0s;
  margin: auto;
  inset: var(--inset);
  border-radius: var(--border-radius);
  background: var(--ion-color-tertiary-tint);
  aspect-ratio: 1;
  color: var(--ion-color-primary);

  .icon-wrapper {
    transform: scale(var(--scale, 0));
    transition: all 0.3s ease-in 0s;
  }

  body[data-theme='dark'] & {
    background: #131038;
  }
}
</style>
