import type { BiometryType } from '@capgo/capacitor-native-biometric';

export enum BiometryTypeGroup {
	FACE = 'face',
	FINGERPRINT = 'touch',
}

export function getBiometryTypeGroup(biometryType: BiometryType): BiometryTypeGroup {
	return [2, 4, 5, 6].includes(biometryType) ? BiometryTypeGroup.FACE : BiometryTypeGroup.FINGERPRINT;
}
