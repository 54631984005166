import { fetchDocuments } from '@/api/document';
import { useUserStore } from '@/store/userStore';
import { DocumentCategory } from '@/types/document-category.enum';
import { DOCUMENT_STATUS_ACTIVE } from '@/types/document-status';
import type { Document } from '@/types/document.interface';
import { defineStore } from 'pinia';
import { type ComputedRef, computed, ref } from 'vue';

export const useDocumentStore = defineStore('document', () => {
	const documents = ref();

	const downloadableDocuments: ComputedRef<Document[]> = computed(() => {
		if (!documents.value) {
			return [];
		}
		return documents.value.page.filter((doc: Document) => {
			return doc.status === DOCUMENT_STATUS_ACTIVE && doc.uploadedBy !== useUserStore().id;
		});
	});

	const uploadedDocuments: ComputedRef<Document[]> = computed(() => {
		if (!documents.value) {
			return [];
		}
		return documents.value.page.filter((doc: Document) => {
			return doc.status === DOCUMENT_STATUS_ACTIVE && doc.uploadedBy === useUserStore().id;
		});
	});

	const getContractPolicy = computed(() => {
		if (downloadableDocuments.value.length === 0) {
			return undefined;
		}
		return downloadableDocuments.value.filter((doc: Document) => {
			return doc.category === DocumentCategory.DOC_POLICY;
		})[0]; // FIXME TODO: refine filter to look for contractId, if the field exists for documents and we can be sure that it is filled
	});

	const getContractOffers = computed(() => {
		if (downloadableDocuments.value.length === 0) {
			return [];
		}
		return downloadableDocuments.value.filter((doc: Document) => {
			return doc.category === DocumentCategory.DOC_OFFER;
		}); // FIXME TODO: refine filter to look for contractId, if the field exists for documents and we can be sure that it is filled
	});

	async function loadDocuments() {
		if (!useUserStore().hasValidAccessToken) {
			return;
		}

		documents.value = await fetchDocuments(1, 100).then((response) => ({
			...response,
			page: response.page.sort(
				(a: Document, b: Document) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
			),
		}));
	}

	async function initialize() {
		await loadDocuments();
	}

	return {
		documents,
		downloadableDocuments,
		uploadedDocuments,

		getContractPolicy,
		getContractOffers,

		loadDocuments,
		initialize,
	};
});
