import router from '@/router';
import { defineStore } from 'pinia';
import { ref } from 'vue';

type OnboardingQueryParams = {
	firstname: string;
	companyId?: string;
	token: string;
};

export const useOnboardingStore = defineStore(
	'onboarding',
	() => {
		const firstName = ref('');
		const companyId = ref('');
		const token = ref('');

		function initStoreFromQueryParams() {
			const queryParams = router.currentRoute.value.query as Partial<OnboardingQueryParams>;
			if (queryParams.firstname) {
				firstName.value = queryParams.firstname;
			}
			if (queryParams.companyId) {
				companyId.value = queryParams.companyId;
			}
			if (queryParams.token) {
				token.value = queryParams.token;
			}
		}

		function $reset() {
			firstName.value = '';
			companyId.value = '';
			token.value = '';
		}

		return {
			firstName,
			companyId,
			token,

			initStoreFromQueryParams,
			$reset,
		};
	},
	{
		persist: {
			storage: sessionStorage,
		},
	}
);
