<script setup lang="ts">
type ButtonType = 'primary' | 'secondary' | 'tertiary';

const buttonTypeMapFill: Record<ButtonType, 'clear' | 'outline' | 'solid'> = {
	primary: 'solid',
	secondary: 'outline',
	tertiary: 'clear',
} as const;

const props = withDefaults(
	defineProps<{
		buttonStyle?: ButtonType;
	}>(),
	{
		buttonStyle: 'primary',
	}
);
</script>

<template>
  <ion-button :class="`d-button-${props.buttonStyle}`" v-bind="$props" :fill="buttonTypeMapFill[props.buttonStyle]">
    <slot />
  </ion-button>
</template>
