import { RouteNames } from '@/router/routeNames';
import { routes } from '@/router/routes';
import { useOnboardingStore } from '@/store/onboardingStore';
import { useUserStore } from '@/store/userStore';
import { LoginStatus } from '@/types/login-status.enum';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import type { Router } from 'vue-router';

for (const [key, value] of Object.entries(routes as Record<string, any>)) {
	value.name = key;
}

const router: Router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: Object.values(routes),
});

router.beforeEach((to) => {
	if (!to.meta.requiredLoginStatus) {
		return;
	}
	const userStore = useUserStore();

	if (
		userStore.hasValidAccessToken &&
		userStore.twoFactorSetupRequired &&
		to.name !== RouteNames.AuthTwoFactorSetup &&
		to.meta.requiredLoginStatus === LoginStatus.LOGGED_IN
	) {
		return { name: RouteNames.AuthTwoFactorSetup }; // require 2FA setup
	}

	if (to.meta.requiredLoginStatus === LoginStatus.GUEST && userStore.hasValidAccessToken) {
		return { name: RouteNames.Dashboard };
	}

	if (to.meta.requiredLoginStatus === LoginStatus.LOGGED_IN && !userStore.hasValidAccessToken) {
		return { name: RouteNames.AuthSignIn, query: { redirect: to.fullPath } };
	}

	if (to.meta.requiresOnboardingToken === true && !useOnboardingStore().token) {
		return { name: RouteNames.AuthSignIn, query: { onboarding: 'true' } };
	}
});

export default router;
