import {
	changePassword,
	getMe,
	loginWithExternalIdentityProvider,
	loginWithTwoFactorCode,
	sendTwoFactorAuthCode,
	userLogin,
} from '@/api/authentication';
import type { GetMeResponse, LoginResponseData } from '@/api/authentication/login.response';
import { isCompanyEnforcing2FA } from '@/api/company';
import { getEmployee } from '@/api/employee';
import { LOGIN_RESPONSE_STATUS } from '@/common/constants';
import Router from '@/router';
import { RouteNames } from '@/router/routeNames';
import { useAppStore } from '@/store/appStore';
import { useBiometricStore } from '@/store/biometricStore';
import { useConsultationStore } from '@/store/consultationStore';
import { useContractStore } from '@/store/contractStore';
import { useDocumentStore } from '@/store/documentStore';
import { useSseListener } from '@/store/index';
import type { EmployeePersonalData } from '@/types/employee-personal-data.type';
import type { SupportedLanguage } from '@/types/language.enum';
import { isMobileDevice } from '@/utils/helperUtils';
import { Capacitor } from '@capacitor/core';
import { Haptics, NotificationType } from '@capacitor/haptics';
import * as Sentry from '@sentry/vue';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { defineStore } from 'pinia';
import { type Ref, computed, ref } from 'vue';

export const useUserStore = defineStore(
	'user',
	() => {
		const id = ref('');
		const accessToken = ref('');
		const accessTokenExpirationInstant = ref(0);
		const username = ref('');
		const firstName = ref('');
		const lastName = ref('');
		const companyId = ref('');
		const email = ref('');
		const personalData: Ref<EmployeePersonalData | undefined> = ref();
		const preferredLanguages = ref(['de' as SupportedLanguage, 'en' as SupportedLanguage]);
		const twoFactor: Ref<any | never[]> = ref({
			methods: [],
			recoveryCodes: [],
		});
		const twoFactorId = ref('');
		const companyEnforcing2FA = ref(false);

		// Getters
		const hasValidAccessToken = computed(() => accessTokenExpirationInstant.value >= Date.now());
		const getFullName = computed(() => `${firstName.value} ${lastName.value}`);
		const mobilePhone2FA = computed(() =>
			Object.keys(twoFactor.value).length === 0 ? '' : twoFactor.value.methods[0]?.mobilePhone
		);
		const twoFactorSetupRequired = computed(() => companyEnforcing2FA.value && !twoFactor.value.methods.length);

		// Actions
		function $reset() {
			id.value = '';
			accessToken.value = '';
			accessTokenExpirationInstant.value = 0;
			username.value = '';
			firstName.value = '';
			lastName.value = '';
			companyId.value = '';
			email.value = '';
			personalData.value = undefined;
			preferredLanguages.value = ['de' as SupportedLanguage, 'en' as SupportedLanguage];
			twoFactor.value = {
				methods: [],
				recoveryCodes: [],
			};
			twoFactorId.value = '';
		}

		async function login(
			loginUsername: string | null,
			loginPassword: string | null,
			saveCredentialsToStore = true,
			oneTimePassword: string | null = null
		): Promise<LOGIN_RESPONSE_STATUS> {
			const loginResponse = await userLogin(loginUsername, loginPassword, oneTimePassword);
			if (!loginResponse) {
				return LOGIN_RESPONSE_STATUS.ERROR;
			}

			if (loginUsername && loginPassword) {
				askIosUserForAutofill(loginUsername, loginPassword);
			}

			if (
				loginResponse.twoFactorId &&
				loginResponse.methods &&
				loginResponse.methods.length > 0 &&
				loginResponse.methods[0]?.id
			) {
				await sendTwoFactorAuthCode(loginResponse.twoFactorId, loginResponse.methods[0].id);
				twoFactorId.value = loginResponse.twoFactorId;
				twoFactor.value.methods = loginResponse.methods;
				return LOGIN_RESPONSE_STATUS.TFA;
			}
			setAccessToken(loginResponse);
			await initializeWithUserData();

			const biometricStore = useBiometricStore();

			if (biometricStore.isBiometricAuthAvailable && saveCredentialsToStore && loginUsername && loginPassword) {
				await biometricStore.updateBiometricAuthData({
					username: loginUsername,
					password: loginPassword,
				});
			}

			useSseListener();

			return LOGIN_RESPONSE_STATUS.SUCCESS;
		}

		async function loginWithGoogle(clientId: string, token: string) {
			const response = await loginWithExternalIdentityProvider(clientId, token);
			if (!response) {
				return LOGIN_RESPONSE_STATUS.ERROR;
			}
			setAccessToken(response);
			await initializeWithUserData();
			return LOGIN_RESPONSE_STATUS.SUCCESS;
		}

		async function loginWithOTP(code: string) {
			const response = await loginWithTwoFactorCode(twoFactorId.value, code);
			if (response === false) {
				return false;
			}
			setAccessToken(response);

			await initializeWithUserData();
			return true;
		}

		async function resendTwoFactorCode() {
			await sendTwoFactorAuthCode(twoFactorId.value, twoFactor.value.methods[0].id);
		}

		function setAccessToken(loginResponse: LoginResponseData) {
			accessToken.value = loginResponse.token;
			accessTokenExpirationInstant.value = loginResponse.tokenExpirationInstant;
			id.value = loginResponse.user.id;
			companyId.value = loginResponse.user.data.companyId;
		}

		async function initializeWithUserData() {
			useAppStore().touchFirstRun();
			const apiResults = await Promise.allSettled([getMe(), isCompanyEnforcing2FA(), getEmployee()]);

			if (apiResults[0].status === 'fulfilled') {
				const me = apiResults[0].value;
				id.value = me.user.data.employeeId;
				username.value = me.user.uniqueUsername;
				firstName.value = me.user.firstName;
				lastName.value = me.user.lastName;
				companyId.value = me.user.data.companyId;
				email.value = me.user.email;
				preferredLanguages.value = me.user.preferredLanguages;
				twoFactor.value = { ...me.user.twoFactor };
			}

			if (apiResults[1].status === 'fulfilled') {
				companyEnforcing2FA.value = apiResults[1].value;
			}

			if (apiResults[2].status === 'fulfilled') {
				const employeeData = apiResults[2].value;
				if (employeeData !== false) {
					personalData.value = {
						...(employeeData.personalDetails as EmployeePersonalData),
						languagePreference: {
							language: employeeData.languagePreference.language,
						},
					};
				}
			}

			setSentryUserScope();

			const consultationStore = useConsultationStore();
			await Promise.allSettled([
				consultationStore.initialize(),
				consultationStore.fetchAppointments(),
				useContractStore().fetchContracts(),
				useDocumentStore().loadDocuments(),
			]);
		}

		async function refreshLogin() {
			$reset();
			const biometricStore = useBiometricStore();
			if (!biometricStore.isBiometricAuthEnabled) {
				return false;
			}
			const { username: loginUsername, password: loginPassword } = await biometricStore.getBiometricAuthData();
			return await login(loginUsername, loginPassword, false);
		}

		async function logout(deleteStoredCredentials = false, redirectUserToSignIn = true) {
			$reset();
			clearSentryUserScope();
			if (deleteStoredCredentials) {
				await useBiometricStore().deleteBiometricAuthData();
			}
			useConsultationStore().$reset();

			if (redirectUserToSignIn) {
				await Router.push({ name: RouteNames.Index });
			}
			await Haptics.notification({
				type: NotificationType.Warning,
			});
		}

		async function updatePassword(currentPassword: string, newPassword: string) {
			const response = await changePassword(currentPassword, newPassword);
			if (response === false) {
				return false;
			}
			if (isMobileDevice()) {
				await useBiometricStore().updateBiometricAuthData({
					password: newPassword,
				});
			}

			return true;
		}

		async function update2FA() {
			const me: GetMeResponse = await getMe();
			twoFactor.value = { ...me.user.twoFactor };
		}

		async function askIosUserForAutofill(username: string, password: string) {
			if (Capacitor.getPlatform() === 'ios') {
				await SavePassword.promptDialog({
					username: username,
					password: password,
				});
			}
		}

		function setSentryUserScope() {
			Sentry.setUser({
				email: email.value,
				fullName: getFullName.value,
			});
		}

		function clearSentryUserScope() {
			Sentry.getCurrentScope().clear();
		}

		async function initialize() {
			if (hasValidAccessToken.value) {
				setSentryUserScope();
				return;
			}
			await refreshLogin();
		}

		return {
			id,
			accessToken,
			accessTokenExpirationInstant,
			username,
			firstName,
			lastName,
			email,
			personalData,
			companyId,
			preferredLanguages,
			twoFactor,
			twoFactorSetupRequired,
			companyEnforcing2FA,

			hasValidAccessToken,
			getFullName,
			mobilePhone2FA,

			initialize,
			login,
			loginWithGoogle,
			loginWithOTP,
			refreshLogin,
			logout,
			updatePassword,
			update2FA,
			resendTwoFactorCode,
		};
	},
	{
		persist: {
			afterRestore() {
				useUserStore().initialize();
			},
		},
	}
);
