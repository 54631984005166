import type { EmployeeResponse } from '@/api/employee/employee.response';
import { useUserStore } from '@/store/userStore';
import type { EmployeePersonalData } from '@/types/employee-personal-data.type';
import { dfetch } from '@/utils/fetchUtils';

export const getEmployee = async (): Promise<EmployeeResponse | false> => {
	const employeeId = useUserStore().id;
	if (!employeeId) {
		return false;
	}
	return dfetch(`/employees/${employeeId}`).catch(() => false);
};

export const updatePersonalData = async (personalData: EmployeePersonalData) => {
	const employeeId = useUserStore().id;
	if (!employeeId) {
		return false;
	}
	return dfetch(`/employees/${employeeId}/personal`, {
		method: 'PUT',
		body: personalData,
	}).catch(() => false);
};
