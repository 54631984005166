import { DATE_FORMAT, TIMEZONE } from '@/common/constants';
import { usePreferencesStore } from '@/store/preferencesStore';
import { type DateInput, type Format, format } from '@formkit/tempo';

export const formatDate = (date: DateInput, dateFormat: Format = DATE_FORMAT) => {
	return format({
		date: date,
		format: dateFormat,
		tz: TIMEZONE,
		locale: usePreferencesStore().getSimpleLanguage,
	});
};
