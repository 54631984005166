import { RouteNames } from '@/router/routeNames';
import { LoginStatus } from '@/types/login-status.enum';
import type { RouteRecordRaw } from 'vue-router';

export const routes: Record<RouteNames, RouteRecordRaw> = {
	Index: {
		path: '/',
		component: () => import('@/views/LoadingScreen.vue'),
	},
	Onboarding: {
		path: '/onboarding',
		alias: '/willkommen',
		redirect: { name: RouteNames.OnboardingStep1 },
	},
	OnboardingWithPlaceholder: {
		path: '/onboarding/:placeholder',
		redirect: { name: RouteNames.OnboardingStep1 },
	},
	WillkommenWithPlaceholder: {
		path: '/willkommen/:placeholder',
		redirect: { name: RouteNames.OnboardingStep1 },
	},
	OnboardingStep1: {
		path: '/onboarding/step-1',
		component: () => import('@/views/onboarding/OnboardingStep1.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.GUEST,
			step: 1,
		},
	},
	OnboardingStep2: {
		path: '/onboarding/step-2',
		component: () => import('@/views/onboarding/OnboardingStep2.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.GUEST,
			step: 2,
		},
	},
	OnboardingStep3: {
		path: '/onboarding/step-3',
		component: () => import('@/views/onboarding/OnboardingStep3.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.GUEST,
			step: 3,
		},
	},
	OnboardingStep4: {
		path: '/onboarding/step-4',
		component: () => import('@/views/onboarding/OnboardingStep4.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.GUEST,
			step: 4,
			requiresOnboardingToken: true,
		},
	},

	AuthTwoFactorSetup: {
		path: '/auth/2fa-setup',
		component: () => import('@/views/auth/TwoFactorSetup.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.LOGGED_IN,
		},
	},
	AuthSignIn: {
		path: '/auth/sign-in',
		component: () => import('@/views/auth/SignIn.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.GUEST,
		},
	},
	AuthForgotPassword: {
		path: '/auth/forgot-password',
		component: () => import('@/views/auth/ForgotPassword.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.GUEST,
		},
	},
	AuthResetPassword: {
		path: '/auth/reset-password',
		alias: '/reset-password/',
		component: () => import('@/views/auth/ResetPassword.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.GUEST,
		},
	},
	Dashboard: {
		path: '/dashboard',
		component: () => import('@/views/dashboard/DashboardPage.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.LOGGED_IN,
		},
	},
	ContractDetail: {
		path: '/contract',
		alias: '/offer',
		component: () => import('@/views/contract/ContractDetail.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.LOGGED_IN,
		},
	},
	Bav: {
		path: '/bav',
		component: () => import('@/views/bav/BavIndex.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.LOGGED_IN,
		},
	},
	BavCalculator: {
		path: '/bav/calculator',
		component: () => import('@/views/bav/BavCalculator.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.LOGGED_IN,
		},
	},
	BavResult: {
		path: '/bav/result',
		component: () => import('@/views/bav/BavResult.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.LOGGED_IN,
		},
	},
	RequestOfferIndex: {
		path: '/request-offer',
		component: () => import('@/views/request-offer/RequestOfferIndex.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.LOGGED_IN,
		},
	},
	RequestOfferFormPersonal: {
		path: '/request-offer/personal',
		component: () => import('@/views/request-offer/RequestOfferFormPersonal.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.LOGGED_IN,
		},
	},
	Consultation: {
		path: '/consultation',
		component: () => import('@/views/consultation/ConsultationIndex.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.LOGGED_IN,
		},
	},
	ConsultationSummary: {
		path: '/consultation/summary',
		component: () => import('@/views/consultation/ConsultationSummary.vue'),
		meta: {
			requiredLoginStatus: LoginStatus.LOGGED_IN,
		},
	},
	Error: {
		path: '/:error',
		component: () => import('@/views/ErrorPage.vue'),
	},
	Privacy: {
		path: '/privacy',
		component: () => import('@/views/PrivacyPage.vue'),
	},
	Terms: {
		path: '/terms',
		component: () => import('@/views/TermsPage.vue'),
	},
	Imprint: {
		path: '/imprint',
		component: () => import('@/views/ImprintPage.vue'),
	},
} as const;

export type Routes = typeof routes;
export type RouteName = keyof Routes;
export type RoutePath = Routes[keyof Routes]['path'];
