import { Preferences } from '@capacitor/preferences';
import { CookieManagement } from '@piwikpro/vue-piwik-pro';
import { defineStore } from 'pinia';
import { type ComputedRef, computed, ref, shallowReadonly } from 'vue';

interface PrivacyOptions {
	allowPiwikPro: boolean;
	cookieAllowed: boolean;
}

export const usePrivacyStore = defineStore('privacy', () => {
	const allowNecessary = ref(true);
	const allowPiwikPro = ref(true);
	const cookieAllowed = ref(false);

	const $state: ComputedRef<PrivacyOptions> = computed(() => ({
		allowPiwikPro: allowPiwikPro.value,
		cookieAllowed: cookieAllowed.value,
	}));

	async function loadStateFromNativeStore() {
		const { value } = await Preferences.get({ key: 'privacy' });
		if (value) {
			const storedPrivacySettings = JSON.parse(value);
			allowPiwikPro.value = storedPrivacySettings.allowPiwikPro;
			cookieAllowed.value = storedPrivacySettings.cookieAllowed;
		}
	}

	async function saveStateToNativeStorage() {
		await Preferences.set({
			key: 'privacy',
			value: JSON.stringify($state.value),
		});
	}

	function applyPiwikSettings() {
		allowPiwikPro.value === true ? CookieManagement.enableCookies() : CookieManagement.disableCookies();
	}

	async function subscriber() {
		applyPiwikSettings();
		await saveStateToNativeStorage();
	}

	function getPrivacySettingsFromQueryParams() {
		const queryParams = Object.fromEntries(new URL(window.location.href).searchParams.entries());
		if (queryParams.allowPiwikPro) {
			allowPiwikPro.value = queryParams.allowPiwikPro === 'true';
		}
		if (queryParams.cookieAllowed) {
			cookieAllowed.value = queryParams.cookieAllowed === 'true';
		}
	}

	async function initialize() {
		await loadStateFromNativeStore();
		getPrivacySettingsFromQueryParams();
		applyPiwikSettings();
	}

	return {
		allowNecessary: shallowReadonly(allowNecessary),
		allowPiwikPro,
		cookieAllowed,

		initialize,
		subscriber,
	};
});
