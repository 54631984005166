<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

type Color =
	| 'primary'
	| 'secondary'
	| 'tertiary'
	| 'success'
	| 'warning'
	| 'danger'
	| 'light'
	| 'medium'
	| 'dark'
	| 'white';

type Size = `${number}px` | `${number}rem`;

const props = defineProps<{
	name: string;
	color?: Color;
	size?: Size;
}>();

const Icon = defineAsyncComponent(
	() => import(`../../assets/icons/${props.name.startsWith('d-') ? props.name : `d-${props.name}`}.svg`)
);

const colorVariable = props.color ? `color: var(--ion-color-${props.color}, currentColor);` : '';

const sizeVariable = props.size ? `--icon-size: ${props.size};` : '';

const styleVariable = `${colorVariable} ${sizeVariable}`;
</script>

<template>
  <div class="icon-wrapper" :style="styleVariable">
    <Icon />
  </div>
</template>
<style scoped>
.icon-wrapper {
  display: grid;
  place-items: center;
  --icon-size: 24px;

  & svg {
    display: block;
    aspect-ratio: 1;
    width: var(--icon-size);
    height: var(--icon-size);
    color: currentColor;
  }

  ion-item & {
    margin-inline-end: 0.5rem;
  }
}
</style>
